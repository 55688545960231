html ::selection {
  background-color: #3390ff;
}

.content_render-text {
  min-width: 100%;
  flex: 1;
}

button:focus {
  box-shadow: none;
  outline: none !important;
}

.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.text__yellow {
  color: #ffdd00 !important;
}

.text__accent {
  color: #01cb65 !important;
}

.text__red {
  color: #fc2a52 !important;
}

.content_render-text div,
.content_render-text b,
.content_render-text section,
.content_render-text span,
.content_render-text p,
.content_render-text b,
.content_render-text ul,
.content_render-text h1,
.content_render-text h2,
.content_render-text h3,
.content_render-text h4,
.content_render-text ol {
  background: transparent !important;
}

.content_render-text ol {
  padding-left: 16px !important;
}

.content_render-text li {
  font-family: 'Roboto-Regular', sans-serif !important;
  font-size: 16px !important;
}

.content_render-text em {
  color: rgba(255, 255, 255, 0.6) !important;
}

.content_render-text h1 span,
.content_render-text h2 span,
.content_render-text h3 span,
.content_render-text h4 span {
  color: #fff !important;
}

.login-form-button {
  width: 200px;
  height: 48px !important;
}

.subtitle {
  font-size: 14px;
  line-height: 16px;
}

.text-12 {
  font-size: 12px !important;
  line-height: 14px !important;
}

.text-18 {
  font-size: 18px !important;
  line-height: 27px !important;
}

.text-16 {
  font-size: 16px !important;
  line-height: 19px !important;
}

.app_bg {
  /*overflow: auto;*/
  min-width: 100%;
  position: relative;
  height: 100%;

  /*min-height: 100vh;*/
}

.modal_footer-btns {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.group_modal-btns {
  margin-top: 20px;
}

input {
  max-width: 100%;
}

.pb-36 {
  padding-bottom: 36px;
}

.btn-outline {
  color: #01cb65;
  background-color: transparent;
}

.my_layout {
  min-height: 100vh;
  position: relative;
}

.title {
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  font-family: 'Roboto-Medium', sans-serif;
  margin: 0;
  letter-spacing: 0.01em;
}

.page_title {
  margin: 0;
  font-family: Roboto-Bold, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.01em;
}

/*.page_title::first-letter {*/
/*  text-transform: uppercase;*/
/*}*/
.pagination_data {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;

  color: #c2cedb;
}

.pagination_data span {
  padding: 1px 8px;
}

.pagination_data-active {
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  border-right: 1px solid #c2cedb;
  color: #01cb65;
}

.action__element,
.action__element-text span,
.action__element-svg svg {
  opacity: 0.9;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.rotate180 {
  transform: rotate(180deg) !important;
}

.my_transition_2 {
  transition: all 0.2s ease;
}

.action__element:hover,
.action__element-text:hover span,
.action__element-svg:hover svg {
  opacity: 0.6;
}

.action__element:active,
.action__element-text:active span,
.action__element-svg:active svg {
  opacity: 1;
}

.disable_el {
  opacity: 0.4 !important;
}

.pointer {
  cursor: pointer !important;
}

.error-boundary-title {
  font-size: 20px;
  color: #fff;
}

.header_bg {
  display: flex;
  align-items: center;
}

.user_info {
  display: flex;
  align-items: center;
  padding: 15px;
}

.user_info > div:last-child {
  flex-grow: 1;
}

.user_info.round {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}

.user_info-avatar {
  min-width: 32px;
  width: 48px;
  height: 48px;
  background: linear-gradient(90deg, #f34e4e 0%, #f4955f 51.56%, #f8c476 100%);
  overflow: hidden;
  margin-right: 10px;
  padding: 1px;
  border-radius: 50%;
}
.user_info-avatar > div {
  background: white;
  padding: 4px;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.user_info-avatar img {
  border-radius: 50%;
}
.user_info-avatar-absent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.user_info-name {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}
.user_info-email {
  font-size: 12px;
  line-height: 18px;
  color: #8991ae;
}

@media (max-width: 600px) {
  .user_info {
    flex-direction: column;
    text-align: center;
  }
  .user_info-avatar {
    margin-bottom: 10px;
  }
  .user_info-name {
    font-size: 16px;
  }
}

.header_logo {
  width: 88px;
  height: 88px;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.header_logo img {
  width: 100%;
  height: auto;
}

.header_logout-btn {
  background-color: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  padding: 4px;
  transition: all 0.3s ease;
}

.header_logout-btn:hover {
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 80px;
}

.login-form {
  width: 100%;
}

.header_dashboard {
  display: inline-block;
  height: auto;
  padding-right: 36px;
}

.menu-item {
  cursor: pointer;
  display: inline-block;
  height: auto;
  margin: 0 12px;
  padding: 4px 12px;
  position: relative;
  color: #32384d;
  /*border-bottom: 4px solid transparent;*/
  transition: all 0.3s ease;
}

.menu-item:hover {
  color: #8991ae;
  text-decoration: none;
}

.menu-item:hover::after,
.menu-item.active::after {
  opacity: 1;
}

.menu-item_event:after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 7px;
  right: 7px;
  background-color: #fc2a52;
}

.bold {
  font-family: 'Roboto-Bold', sans-serif !important;
}

.breadcrumbs {
  display: flex;
  padding: 0 0 16px;
  align-items: center;
}

.breadcrumbs_separator {
  display: flex;
  align-items: center;
  padding: 0 5px;
  height: 16px;
}

.breadcrumbs_link {
  cursor: pointer !important;
  color: #8991ae;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.breadcrumbs_curr {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.breadcrumbs_link:hover {
  color: #32384d;
  text-decoration: none;
}

.breadcrumbs_link:active {
  color: #32384d;
  text-decoration: none;
}

.breadcrumbs_link:hover + span path {
  fill: #32384d;
}

.breadcrumbs_link:hover + span {
  transform: rotate(180deg);
}

div.breadcrumbs_link {
  cursor: default;
}

.my_svg svg {
  width: 10px;
  height: 10px;
}

.loading_element {
  position: relative;
}

.loading_element-spinner {
  position: absolute;
  z-index: 333;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.small__text {
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;
}

.text__overflow {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text__muted-12 {
  font-size: 12px;
  line-height: 17px;
  /* identical to box height, or 167% */
}

.text__muted {
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 167% */
}

.text__muted-16 {
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 167% */
}

.page_head {
  margin-bottom: 40px;
}

.page_head h1 {
  margin: 0;
  font-size: 40px;
  line-height: 47px;
  font-family: 'Roboto-Bold', sans-serif;
}

.course_users-count {
  font-size: 22px;
  line-height: 26px;
}

.courses_head h1 {
  font-size: 32px;
  line-height: 37px;
  letter-spacing: 0.01em;
}

.page_head .text__muted {
  font-size: 14px;
  line-height: 21px;
}

.slick-active .course_slider-item-active {
  padding: 0;
}

.course_slider-xwrap {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.course_slider-item-x1 {
  /*opacity: 0.3;*/
  width: 370px;
  height: 370px;
  object-fit: contain;
  position: absolute;
  bottom: -130px;
  right: -85px;
  /*filter: blur(8px);*/
  z-index: 1;
  background-size: contain;
  background-repeat: no-repeat;
  /*backdrop-filter: blur(2px);*/
  display: flex;
  align-items: center;
  justify-content: center;
}

.x_line-1 {
  position: absolute;
  background-color: rgba(252, 42, 82, 0.64);
  backdrop-filter: blur(2px);
  /*opacity: 0.64;*/
  width: 285px;
  height: 74px;
  transform: rotate(35deg);
}

.x_line-2 {
  position: absolute;
  /*left: 0;*/
  background-color: green;
  top: 72px;
  right: 92px;
  width: 100px;
  height: 74px;
  transform: rotate(-55deg);
}

.x_line-3 {
  position: absolute;
  /*left: 0;*/
  background-color: green;
  bottom: 78px;
  right: 190px;
  width: 113px;
  height: 74px;
  transform: rotate(-55deg);
}

.course_slider-item-x2 {
  width: 310px;
  object-fit: contain;
  position: absolute;
  bottom: -100px;
  left: -130px;
  z-index: 1;
}

.course_slider-item-number {
  position: absolute;
  bottom: -16px;
  right: 8px;
  font-size: 68px;
  line-height: 96px;
  /* or 58% */
  letter-spacing: 0.01em;

  color: #8991ae;
  font-family: Roboto-Bold, sans-serif;
  z-index: 1;
  opacity: 0.1;
}

.course_slider-item-number span {
  font-size: 96px;
}

.course_about-title {
  font-size: 32px;
  line-height: 48px;
  /* identical to box height, or 150% */
  letter-spacing: 0.01em;
}

.course_slider-item-go {
  transition: all 0.3s ease;
  min-width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 4px;
  top: 4px;
  background: #32384d;
  z-index: 1;
  align-self: flex-end;
  font-size: 16px;
  display: none;
  cursor: pointer;
}

.course_slider-item-go:hover {
  background: #8991ae;
}

.course_logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 48px;
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.course_logo-bg {
  position: absolute;
  left: 1px;
  top: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.course_logo img {
  position: relative;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.course_about-slides {
  text-align: right;
  font-size: 20px;
  color: #8f9c96;
  flex: 0 100px;
  min-width: 100px;
}

.course_about-slides span {
  color: #fff;
  font-size: 32px;
  line-height: 37px;
}

.course_about-description {
  position: relative;
  /* or 150% */
  color: #32384d;
}

.read_more-text {
  position: relative;
}

.read_more-text:after {
  opacity: 0.4;
  content: '';
  display: block;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #272a2f 100%);
}

.read_more-btn {
  display: inline-block;
  letter-spacing: 0.01em;
  cursor: pointer;
  color: #f4955f;
  transition: all 0.3s ease;
  margin-top: 8px;
}

.read_more-btn:hover {
  opacity: 0.8;
  text-decoration: underline;
}

.read_more-btn:active {
  opacity: 1;
}

.custom_select-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 127px;
  border-radius: 4px;
}

.custom_select-value,
.custom_select-hidden-item {
  width: 100%;
  background: #3d4044;
  /* Запрещаем перенос строк */
  padding: 0 24px;
  height: 32px;
}

.custom_select-value span,
.custom_select-hidden-item span {
  width: 100%;
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom_select-value {
  border-radius: 4px;
  cursor: default;
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 32px;
}

.custom_select-hidden-item {
  flex-wrap: wrap;
  height: auto;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #fff;
}

.custom_select-hidden-item:hover {
  background: rgba(255, 253, 253, 0.04);
}

.custom_select-chevron {
  position: absolute;
  right: 4px;
  transition: all 0.3s ease;
}

.custom_select-hidden {
  z-index: 1;
  background: #3d4044;
  width: 100%;
  left: 0;
  position: absolute;
  display: none;
  top: 100%;
  min-height: 28px;
  border-radius: 0 0 4px 4px;
  box-shadow: 2px 3px 3px rgba(24, 27, 32, 0.6);
  max-height: 300px;
  overflow: auto;
}

.custom_select-hidden-item:last-child {
  border-radius: 0 0 4px 4px;
}

.custom_select-visible .custom_select-value {
  border-radius: 4px 4px 0 0;
  color: rgba(255, 255, 255, 0.4);
}

.custom_select-visible .custom_select-hidden {
  display: block;
}

.custom_select-visible .custom_select-chevron {
  transform: rotate(180deg);
}

.pagination_limits-select {
  min-width: 40px;
}

.pagination_limits-select .custom_select-value {
  padding-right: 16px;
  padding-left: 8px;
}

.pagination_limits-select .custom_select-hidden-item {
  justify-content: center;
  padding-right: 16px;
  padding-left: 8px;
}

.content_image {
  margin-bottom: 16px;
  margin-right: 16px;
  overflow: hidden;
}

.content_image img {
  object-fit: contain;
  max-width: 100%;
}

.modal_task {
  position: relative;
  height: 120px;
  overflow: hidden;
}

.modal_task-open {
  height: auto;
}

.modal_task:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to top, #181b20 0%, #181b20 2%, transparent 80%);
}

.modal_task-open:after,
.modal_task-nomore:after {
  display: none;
}

.content_youtube iframe {
  border-radius: 4px;
  overflow: hidden;
}

.content_video {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  max-height: 300px !important;
}

.content_video > div {
  margin-right: 0 !important;
  width: 100%;
  max-height: 300px !important;
  outline: none;
}

.content_video video {
  max-width: 100%;
  outline: none;
  max-height: 300px !important;
  background-color: #000;
  /*height: auto !important;*/
}

.video-react .video-react-big-play-button {
  border-color: #3fd5be !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 72px !important;
  height: 72px !important;
  border-radius: 144px !important;
}

.video-react .video-react-big-play-button:before {
  color: #3fd5be;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content_file-icon {
  width: 24px;
  height: 24px;
  background: #ff9533 !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
}

.content__file-name {
  max-width: 200px;
  display: flex;
}

.content__file-name a {
  white-space: nowrap;
  font-size: 12px;
  line-height: 17px;
}

.content__file-name a:hover {
  text-decoration: underline;
}

.task_chat {
  position: relative;
  /*margin-top: 32px;*/
  margin: 0 -32px;
}

.chat_title {
  border-top: 1px solid rgba(255, 255, 255, 0.04);
  padding: 16px 32px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Roboto-Medium', sans-serif;
  line-height: 21px;
  font-size: 16px;
}

.chat_body {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*flex-wrap: wrap;*/
  padding: 0 32px;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.chat_tobottom {
  position: absolute;
  right: 48px;
  bottom: 24px;
  width: 48px;
  height: 48px;
  background: #1b1e23;
  border: 2px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.chat_body-item {
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
}

.chat_body-message {
  position: relative;
  background: rgba(255, 255, 255, 0.08);
  padding: 16px 16px 8px;
  font-size: 16px;
  line-height: 24px;
  color: #b2b3b5;
  flex: 1;
  word-wrap: break-word;
  max-width: calc(100% - 64px);
}

.chat_body-item.his .chat_body-message {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.chat_body-time {
  padding-top: 4px;
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.32);
}

.chat_body-message a {
  color: #01cb65;
}

.chat_body-message a:hover {
  color: #01cb65;
}

.chat_body-message a:active {
  opacity: 0.9;
}

.chat_body-item.his .chat_body-message:after,
.chat_body-item.his .chat_body-message:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 0 4px 4px;
  border-color: #181b20 transparent;
  display: block;
  width: 0;
  bottom: 0;
  left: -4px;
  right: -30px;
  top: 15px;
  z-index: 2;
}

.chat_body-item.his .chat_body-message:after {
  border-width: 0 0 5px 4px;
  bottom: -1px;
  left: -5px;
  border-color: rgba(255, 255, 255, 0.08) transparent;
  z-index: 1;
}

.chat_body-item.own .chat_body-message:after {
  content: '';
  position: absolute; /* Абсолютное позиционирование */
  left: -4px;
  bottom: 0; /* Положение треугольника */
  border: 4px solid transparent; /* Прозрачные границы */
  border-top: 2px solid rgba(255, 255, 255, 0.08); /* Добавляем треугольник */
  border: 2px solid transparent;
  border-right: 2px solid rgba(255, 255, 255, 0.08);
  border-bottom: 2px solid rgba(255, 255, 255, 0.08);
}

.chat_body-status {
  display: flex;
  align-items: center;
  padding-left: 16px;
  position: relative;
  /*border-left: 1px solid rgba(255, 255, 255, 0.16);*/
  height: 100%;
  width: 100%;
}

.chat_body-status:before {
  content: '';
  position: absolute;
  left: 0;
  top: -16px;
  height: calc(100% + 16px);
  width: 1px;
  background-color: rgba(255, 255, 255, 0.16);
}

.chat_body-status:after {
  content: '';
  position: absolute;
  left: -6px;
  height: 14px;
  width: 14px;
  border: 4px solid #181b20;

  border-radius: 50%;
}

.chat_body-status-check:after {
  background-color: #ffdd00;
}

.chat_body-status-rework:after {
  background-color: #fc2a52;
}

.chat_body-status-accepted:after {
  background-color: #01cb65;
}

.chat_body-status-null:after {
  display: none;
}

.chat_date {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  width: 100%;
  position: absolute;
  padding-top: 8px;
}

.chat_form {
  margin: 0 -32px;
  padding: 16px 32px;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.25);
}

.chat_form-add {
  position: relative;
  /*margin-right: 8px;*/
}

.chat_form-wrap {
  padding: 8px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.08);
  display: flex;
  align-items: flex-end;
}

.chat_form-wrap > div {
  min-height: 32px;
}

.chat_form-wrap textarea {
  resize: none;
  background-color: transparent;
  border: 0 !important;
  outline: none !important;
  color: #fff;
  height: 32px;
}

.chat_form-wrap textarea::placeholder {
  color: rgba(255, 255, 255, 0.4) !important;
}

.chat_form-btn-accepted {
  margin-left: 24px;
  font-size: 12px;
  line-height: 14px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px 0 0 4px;
  padding: 8px 24px;
  color: #01cb65;
  margin-right: 1px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.chat_form-btn-accepted:hover,
.chat_form-btn-rework:hover {
  background: rgba(255, 255, 255, 0.12);
}

.chat_form-btn-accepted:active,
.chat_form-btn-rework:active {
  background: rgba(255, 255, 255, 0.04);
}

.chat_form-btn-rework {
  font-size: 12px;
  line-height: 14px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 0 4px 4px 0;
  padding: 8px 24px;
  color: #ffdd00;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.chat_form-btn-twit {
  width: 32px;
  background: #01cb65;
  border-radius: 4px;
  height: 32px;
  box-shadow: 0 0 4px #01cb65;
  margin-left: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat_form-btn-twit:hover {
  box-shadow: none;
}

.chat_form-btn-twit:active {
  opacity: 0.8;
}

.task_status-2,
.task_status-rework {
  color: #fc2a52;
}

.task_status-3,
.task_status-accepted {
  color: #01cb65;
}

.task_status-1,
.task_status-check {
  color: #ffdd00;
}

.secondary_btn {
  font-size: 14px;
  width: 131px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  height: 32px;
  align-items: center;
  padding: 0 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 0;
  outline: none;
}

.secondary_btn-disabled {
  pointer-events: none;
  opacity: 0.3;
}

.secondary_btn:hover {
  background: rgba(255, 255, 255, 0.12);
}

.secondary_btn:active {
  outline: none;
  background: rgba(255, 255, 255, 0.04);
}

.secondary_btn:focus {
  outline: none;
}

.secondary_btn-inline {
  width: auto;
}

.accent_text {
  color: #01cb65;
}

.accent_btn {
  cursor: pointer;
  background: #32384d;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 20px;
  transition: all 0.3s ease;
  border-radius: 6px;
  box-shadow: 0px 20px 15px rgba(45, 60, 88, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  /* Note: backdrop-filter has minimal browser support */
  min-width: 143px;
  transition: all 0.3s ease;
  padding: 10px 20px;
  border: 1px solid transparent;
  outline: none;
  opacity: 0.7;
}

.accent_btn:not(.disabled) {
  opacity: 1;
}

.accent_btn:hover {
  box-shadow: 0px 2px 5px rgba(45, 60, 88, 0.2);
}

.accent_btn:active {
  box-shadow: 0px 2px 5px rgba(45, 60, 88, 0.2);
}

.accent_btn:focus {
  outline: none;
}

.accent_btn.loading {
  pointer-events: none;
}

.btn_dark {
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 110px;
  font-size: 14px;
  line-height: 16px;
  height: 32px;
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(4px);
}

.btn_dark:hover {
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.04);
}

.btn_dark:active {
  background: rgba(255, 255, 255, 0.03);
}

.btn_dark:focus {
  outline: none;
}

.accent_btn-outline {
  cursor: pointer;
  padding: 10px 20px;
  background: #8991ae;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 20px;
  transition: all 0.3s ease;
  border-radius: 6px;
  border: none;
  box-shadow: 0px 20px 15px rgba(45, 60, 88, 0.15);
  align-items: center;
  justify-content: center;
  /* Note: backdrop-filter has minimal browser support */
  min-width: 143px;
  font-weight: 400;
  outline: none;
}

.accent_btn-outline:hover {
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.04);
}

.accent_btn-outline:focus {
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.04);
}

.modal_title {
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  font-family: 'Roboto-Regular', sans-serif;
  letter-spacing: 0.01em;
}

.custom_input {
  appearance: none !important;
  border-radius: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.04);
  border: 0;
  border-bottom: 1px solid transparent;
  height: 32px;
  outline: none;
  padding: 0 16px;
}

.custom_input::placeholder {
  color: rgba(255, 255, 255, 0.64) !important;
  font-size: 14px;
  /*line-height: 24px;*/
  letter-spacing: initial;
}

.custom_textarea {
  appearance: none !important;
  background-color: #181b20 !important;
  border-radius: 4px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.16);
  outline: none;
  padding: 6px 16px;
  min-height: 32px;
  line-height: 19px;
  resize: none;
}

.custom_textarea::placeholder {
  color: rgba(255, 255, 255, 0.64) !important;
  font-size: 14px;
  /*line-height: 19px;*/
  letter-spacing: initial;
}

.custom_input:focus,
.custom_textarea:focus {
  border-color: #32384d;
}

.no_results {
  text-align: center;
}

.group_modal-add_student {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  width: 100%;
  cursor: pointer;
  line-height: 24px;
  color: #f34e4e;
}
.group_modal-add_student:hover {
  color: #f34e4e;
}

.content_youtube-link {
  color: #fff;
  text-decoration: underline;
}

.accent__hover,
.accent__hover-red {
  transition: all 0.3s ease;
  cursor: pointer;
  display: inline-block;
}

.accent__hover:hover {
  color: #8991ae;
  text-decoration: none;
}

.accent__hover path,
.accent__hover-red path {
  transition: all 0.3s ease;
}

.accent__hover:hover path {
  fill: #8991ae;
}

.accent__hover-red:hover {
  color: #fc2a52;
}

.accent__hover-red:hover path {
  fill: #fc2a52 !important;
}

.custom_checkbox-wrap {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border: 4px solid #ced4e4;
  box-sizing: border-box;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: white;
}

.custom_checkbox-wrap .svg-wrap {
  position: relative;
  top: -4px;
}

.custom_checkbox-icon {
  display: none;
}

.custom_checkbox-checked .custom_checkbox-icon {
  display: block;
}

.custom_checkbox-disabled {
  background: #ced4e4;
  box-shadow: inset 0px 1px 1px rgba(50, 56, 77, 0.25);
  border: none;
}

.custom_option {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  border-radius: 50%;
  padding: 2px;
  border: 4px solid #ced4e4;
  background: white;
  box-sizing: border-box;
  position: relative;
}

.custom_option::after {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: transparent;
  position: absolute;
  top: 3px;
  left: 3px;
  transition: background-color 0.3s ease;
}

.custom_option.active::after {
  background-color: #32384d;
}

.custom_input-password {
  letter-spacing: 10px;
  position: relative;
  padding-right: 32px;
}

.custom_input-password-eye {
  position: absolute;
  right: 8px;
  cursor: pointer;
}

.custom_input-password-visible {
  letter-spacing: initial;
}

.input_icon {
  position: absolute;
  left: 8px;
}

.custom_input-icon {
  padding-left: 40px;
}

.accent_btn.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.section_list-item {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-top: 1px solid #8991ae;
  border-bottom: 1px solid transparent;
  padding: 16px;
}

.section_list-padding:first-child .section_list-item {
  border-top: 1px solid transparent;
}

.section_list-padding.section:hover {
  padding-right: 72px;
}

.section_list-padding:last-child .section_list-item {
  border-bottom: 1px solid #8991ae;
}

.section_list-padding {
  position: relative;
  margin: 10px 0;
  overflow-x: hidden;
}
.end_section_today {
  overflow: hidden;
}

.section_list-padding.disabled {
  fill: #fc2a52 !important;
  pointer-events: none;
  color: rgba(255, 255, 255, 0.64);
}

.section_list-padding.section_list-timer {
  pointer-events: auto;
}

.section_list-padding.section_list-timer .section_list-item {
  border-top-color: #ffdd00;
}

.section_list-padding.disabled .section_list-subtitle {
  color: rgba(255, 255, 255, 0.32);
}

.section_list-padding.disabled path {
  fill: rgba(255, 255, 255, 0.32) !important;
}

.section_list-padding.disabled .section_list-arrow {
  display: none;
}

.section_list-padding .section_list-arrow {
  display: none;
  cursor: pointer;
  transition: background 0.3s ease;
  position: absolute;
  right: 0;
  top: 0;
  width: 72px;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #01cb65;
  transform: translateX(64px);
}

.section_list-padding .section_list-arrow:hover {
  background: #34d584;
}

.section_list-padding .section_list-arrow:active {
  background: #06a858;
}

.section_list-padding .section_list-lock {
  display: none;
  padding-left: 8px;
}

.section_list-padding.active .section_list-arrow,
.section_list-padding.section_list-timer .section_list-lock {
  display: flex;
}

.section_list-padding.section:hover .section_list-arrow {
  transform: translateX(0);
  display: flex;
}

.section_list-padding.section:hover .section_list-title {
  padding-right: 8px;
}

.section_list-padding.active {
  background: #fff;
  box-shadow: 0px 30px 20px rgba(45, 60, 88, 0.15);
  border-radius: 6px;
  cursor: default;
}

.section_list-padding.end_section {
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 30px 20px rgba(45, 60, 88, 0.15);
  border-radius: 6px;
}

.section_list-padding:not(.active):hover .section_list-item {
  background: #fff;
  border-radius: 6px;
}

.section_list-padding:hover + .section_list-padding .section_list-item {
  border-top-color: transparent;
}

.section_list-padding:hover .section_list-item {
  border-color: transparent;
}

.section_list-padding.active .section_list-item {
  border: 1px solid transparent;
}

.section_list-padding.active + .section_list-padding .section_list-item {
  border-top: 1px solid transparent;
}

.section_list-padding.end_section .section_list-item {
  border-color: transparent;
}

.section_list-padding.end_section .title {
  position: relative;
  z-index: 1;
}

.section_list-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
}

.section_list-icon.test {
  border-radius: 50%;
  overflow: hidden;
}

.section_list {
  position: sticky !important;
  width: 100%;
  top: 0;
  padding-right: 64px;
  /*overflow-y: auto;*/
  padding-left: 24px;
  margin-left: -24px;
  overflow-y: auto;
  max-height: calc(100vh - 24px);
  padding-bottom: 40px;
}

.section_list-type {
  position: relative;
  height: 48px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;
  margin-right: 24px;
  padding-right: 40px;
}

.section_list-type:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 64px);
  height: 1px;
  background: rgba(255, 255, 255, 0.08);
}

.render_content strong {
  font-family: 'Roboto-Bold', sans-serif;
}

.render_content p,
.render_content span {
  margin: 0;
}

.content_body-item {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 16px;
}

.content_body-item:last-child {
  padding-bottom: 0;
}

.content_body-item.text,
.content_body-item.file,
.content_body-item.video {
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  flex: 1 100%;
}

.render_content h1,
.render_content h2 {
  margin: 0 !important;
  background-color: transparent !important;
  padding-top: 16px;
  padding-bottom: 16px;
  color: #fff !important;
}

.content_render-text h1:last-child,
.content_render-text h2:last-child {
  padding-bottom: 0 !important;
}

.render_content strong {
  color: rgba(255, 255, 255, 0.6);
}

.render_content ul,
.render_content ol {
  padding-left: 32px;
  color: rgba(255, 255, 255, 0.64);
}

.data_type-checklist .content_heading,
.data_type-poll .content_heading {
  padding: 16px 24px;
  margin-bottom: 16px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
}

.content_heading {
  position: relative;
  font-family: 'Roboto-Medium', sans-serif;
  font-size: 20px;
  line-height: 30px;
}

.content_heading-bg {
  position: absolute;
  left: -12px;
  top: -4px;
  width: 24px;
  height: 16px;
  z-index: -1;
}

.test_q-count {
  color: #fc2a52;
}

.test_q-from {
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.2em;
}

.test_dots:first-child {
  margin-left: 0;
}

.test_dots {
  cursor: pointer;
  padding: 8px 0 !important;
  margin: 0 4px;
  max-width: 40px !important;
}

.test_dots-el {
  height: 1px;
  background: rgba(255, 255, 255, 0.64);
}

.test_dots:hover .test_dots-el {
  background: #fff;
}

.test_dots.active .test_dots-el {
  background: #fc2a52;
}

.test_score {
  font-size: 16px;
  margin-bottom: 10px;
}

.test_score-loader {
  display: inline-flex;
  align-items: center;
}

.test_title {
  font-weight: 600;
  font-size: 30px;
  /* or 140% */
  letter-spacing: 0.01em;
}

.test_title-image {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  display: inline-block;
}

.test_title-image img {
  max-height: 200px;
  max-width: 375px;
  object-fit: cover;
  object-position: left top;
}

.test_answers-item {
  display: flex;
  align-items: center;
  padding: 0 24px;
  width: 100%;
  height: 48px;
  margin-top: 32px;
  background: rgba(255, 255, 255, 0.08);
  /* Note: backdrop-filter has minimal browser support */
  font-size: 16px;
  line-height: 19px;
  border-radius: 4px;
}

.test_answers-item.true {
  color: #01cb65;
}

.test_result-buttons {
  display: flex;
  justify-content: space-between;
}

.dashboard_item {
  position: relative;
  background: rgba(255, 255, 255, 0.08);

  border-radius: 12px;
  padding: 24px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard_item:after {
  z-index: -1;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 12px;
  border: 3px solid transparent;
}

.dashboard_item:hover:after {
  border-color: #01cb65;
}

.dashboard_item-profiles {
  height: 266px;
  margin-bottom: 40px;
}

.dashboard_item .accent__hover {
  align-self: flex-start;
}

.dashboard_title {
  font-family: 'Roboto-Bold', sans-serif;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.01em;
  padding: 0 32px 32px 8px;
  color: #ffffff;
}

.dashboard_title span {
  color: #fc2a52;
}

.dashboard_item-events {
  height: 266px;
}

.dashboard_item-tasks {
  height: 572px;
}

.dashboard_item-notifications {
  height: 572px;
  justify-content: flex-start;
}

.dashboard_item-events_time {
  flex: 0 50px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  min-width: 50px;
}

.dashboard_item-events_name {
  cursor: default;
  display: flex;
  align-items: center;
  flex: 1 calc(100% - 130px);
  width: 100%;
  max-width: calc(100% - 130px);
  min-height: 56px;
  padding: 12px 8px 12px 15px;
  border-left: 1px solid rgba(255, 255, 255, 0.16);
}

.dashboard_item-events .secondary_btn {
  flex: 0 131px;
  min-width: 131px;
}

.dashboard_item-tasks_list-wrap {
  margin: 0 -24px;
  flex: 1;
  flex-direction: column;
  overflow-y: hidden;
  display: flex;
}

.dashboard_item-tasks_list {
  flex: 1;
  overflow: auto;
  padding: 0 24px;
}

.dashboard_item-tasks .dashboard_title {
  padding-left: 32px;
}

.dashboard_item-tasks_list > div:last-child {
  margin: 0 !important;
}

.dashboard_item-notifications-date {
  align-self: center;
  min-width: 90px;
  flex: 0 90px;
  padding-right: 16px;
}

.dashboard_item-notifications-name {
  padding-left: 16px;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
  margin-right: 8px;
  line-height: 19px;
}

.dashboard_item-notifications-name span {
  width: 100%;
  word-wrap: break-word;
}

.dashboard_item-notifications-list {
  position: relative;
  overflow: auto;
  margin: 0 -24px -24px;
}

.dashboard_item-notifications-list::-webkit-scrollbar-track {
  margin-bottom: 8px;
}

.dashboard_item-events .secondary_btn {
  flex: 0 32px;
  min-width: 32px;
  padding: 0;
  align-items: center;
  justify-content: center;
}

.auth_page {
  display: flex;
  /*position: relative;*/

  min-height: 100vh;
  width: 100%;
  /*overflow: auto;*/
  /*padding-bottom: 100px;*/
}

.auth_page:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 500px;
  height: 500px;
  background: linear-gradient(142.92deg, #7459ff -75.15%, rgba(255, 255, 255, 0) 34.88%);
}

.auth_page h1 {
  font-size: 40px;
  line-height: 47px;
  padding-bottom: 40px;
  font-family: 'Roboto-Black', sans-serif;
  margin-bottom: 0;
}

.auth_page blockquote {
  position: relative;
  z-index: 1;
  font-size: 32px;
  line-height: 48px;
  font-family: 'Roboto-Medium', sans-serif;
  margin-bottom: 32px;
}

.auth_page .row {
  padding: 32px 0;
}

.profile_avatar {
  border-bottom: 1px solid #ced4e4;
  border-top: 1px solid #ced4e4;
}
.profile_avatar .user_info-avatar {
  width: 60px;
  height: 60px;
  overflow: hidden;
}

.profile_avatar-edit .user_info-avatar {
  width: 48px;
  height: 48px;
  overflow: hidden;
}

.profile_modal {
}

.profile_modal-name {
  margin-bottom: 0;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.01em;
}

.profile_modal_center {
  padding: 0 32px;
}

.profile_icon {
  position: absolute;
  left: -32px;
}

.profile_avatar-edit_icon {
  transition: all 0.3s ease;
  position: absolute;
  /*bottom: -30px;*/
  transform: translateY(60px);
  pointer-events: none;
}

.profile_avatar-edit_wrap {
  position: relative;
  margin-bottom: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.profile_avatar-edit_wrap:hover .profile_avatar-edit_icon {
  transform: translateY(0px);
}

.dashboard_notifications-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 14px 24px;
}

.dashboard_notifications-item:after {
  content: '';
  width: 1px;
  height: 100%;
  left: 113px;
  top: 0;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.16);
}

.dashboard_notifications-item:last-child {
  padding-bottom: 32px;
}

.dashboard_item-notifications-trash {
  opacity: 0;
}

.dashboard_notifications-item:hover .dashboard_item-notifications-trash {
  opacity: 1;
}

.dashboard_notifications-item:hover {
  background: rgba(255, 253, 253, 0.04);
}

.course_about {
  position: relative;
  z-index: 1;
  padding-bottom: 24px;
}

.news_about {
  padding-bottom: 24px;
}

.events_about {
  padding-bottom: 24px;
}

.event_link {
  position: relative;
  z-index: 1;
  text-decoration: underline;
  color: #01cb65;
  word-break: break-word;
  margin-bottom: 16px;
}

.event_link:hover {
  text-decoration: none;
  color: #01cb65;
}

.news_about-content {
  margin-top: -74px;
  margin-right: -20px !important;
  margin-left: -20px !important;
}

.news_about-content > div {
  padding-left: 20px;
  padding-right: 20px;
}

.course_slider-progress.circle {
  width: 64px;
}

.course_slider-circles {
  position: absolute;
  transition: all 0.3s ease;
  bottom: 100%;
  right: 15px;
  top: 20px;
  background: linear-gradient(180deg, #f34e4e 0%, #f4955f 51.56%, #f8c476 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #f4955f;
  display: table;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
}

.course_continue {
  height: 104px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  background: #fff;
  color: #32384d;
  border-radius: 6px;
  box-shadow: 0px 30px 20px rgba(45, 60, 88, 0.15);
  padding: 16px 32px;
}

.course_continue-timer {
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 30.05%, rgba(255, 221, 0, 0.08) 100%);
}

.course_continue.finish {
  position: relative;
  overflow: hidden;
  height: 104px;
  justify-content: flex-end;
}

.animate_confetti {
  animation: confetti 1.5s alternate infinite ease-in-out;
}

@keyframes confetti {
  0% {
    opacity: 1;
  }
  35% {
    opacity: 1;
  }
  49% {
    transform: rotate(0deg);
    opacity: 0;
  }
  50% {
    opacity: 0;
    transform: rotate(15deg);
  }
  51% {
    transform: rotate(15deg);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }

  100% {
    transform: rotate(15deg);
    opacity: 1;
  }
}

.section_head {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.section_head.direction {
  /*min-height: 200px;*/
}
.section_head h1 {
  padding-top: 32px;
  position: relative;
  z-index: 1;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.01em;
  font-family: 'Roboto-Black', sans-serif;
}

.section_head-bg {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  left: 0;
  bottom: -70px;
  z-index: 0;
  right: 0;
}

.section_head-wrap {
  position: relative;
}

.section_head-image {
  align-self: flex-end;
  max-width: 300px;
  max-height: 300px;
}

.section_head-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.section_head-3x {
  /*position: absolute;*/
  /*z-index: 1;*/
  position: absolute;
  right: 80%;
  bottom: -140px;
  max-width: 400px;
  margin-right: -100px;
  max-height: 620px;
}

.section_head-x {
  /*position: absolute;*/
  /*z-index: 1;*/
  position: absolute;
  left: -20px;
  bottom: -200px;
  max-width: 500px;
  max-height: 500px;
}

.content_data {
  display: flex;
  flex-wrap: wrap;
}

.content_name-x {
  position: absolute;
  left: -40px;
  top: -20px;
  z-index: -1;
}

.course_done {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  /*margin-bottom: 32px;*/
  border: 4px solid #f4955f;
  border-radius: 50%;
}

.check_item {
  display: flex;
  align-items: center;
  word-break: break-word;
  min-height: 48px;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
  background: #f0f4fb;
  border-radius: 6px;
  margin-bottom: 10px;
  column-gap: 10px;
}

.check_item__icon {
  margin-left: auto;
}
.check_item__icon .svg-wrap {
  vertical-align: middle;
}

.check_item.checked {
  text-decoration: line-through;
}

.check_item.disabled {
  color: #8991ae;
  pointer-events: none;
}

.end_checklist-wrap {
  margin: 0 auto;
  position: relative;
  width: 430px;
  height: 430px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  border: 1px solid rgba(255, 255, 255, 0.08);
}

.end_checklist-bg {
  background-color: #01cb65;
  width: 240px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.task_btn {
  display: flex;
  position: -webkit-sticky !important;
  position: sticky;
  bottom: 24px;
  margin-top: 24px;
}

.task_head_status {
  display: flex;
  align-self: flex-start;
  min-width: 300px;
  flex: 1;
  max-width: 250px;
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  font-family: 'Roboto-Medium', sans-serif;
  letter-spacing: 0.01em;
}

.task_head_status.check {
  color: #ffdd00;
}

.task_head_status.rework {
  color: #fc2a52;
}

.task_head_status.accepted {
  color: #01cb65;
}

.poll_question {
  display: flex;
  align-items: center;
  padding-bottom: 24px;
}

.poll_question span {
  font-size: 20px;
  line-height: 30px;
  font-family: 'Roboto-Medium', sans-serif;
}

.poll_answer {
  word-wrap: break-word;
  width: 100%;
  background: rgba(255, 255, 255, 0.04);
  padding: 12px 24px;
  font-size: 16px;
}

.poll_check {
  padding-bottom: 24px;
  font-size: 16px;
  color: #ffdd00;
  display: flex;
  align-items: center;
}

.poll_item {
  padding-bottom: 48px;
}

.test_q_count {
  font-size: 16px;
  line-height: 28px;
  padding-bottom: 24px;
}

.test_title {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.01em;
  padding-bottom: 32px;
}

.test_image {
  object-fit: contain;
  max-height: 368px;
  max-width: 100%;
  margin-bottom: 32px;
}

.test_answer-item {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: flex-start;

  padding: 13px 15px;
  margin-bottom: 10px;
  word-wrap: break-word;
  max-width: 100%;
  border-radius: 6px;
  background: #f0f4fb;
}

.test_answer-item:last-child {
  margin-bottom: 40px;
}

.test_answer-item:after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border-radius: 6px;
  border: 2px solid transparent;
}

.test_answer-item span {
  max-width: 300px;
  display: inline-block;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.stars_wrap {
  display: flex;
  flex-wrap: nowrap;
}

.stars_wrap i {
  margin: 0 8px;
  font-size: 24px;
}

.stars_wrap.small i {
  font-size: 10px;
  margin: 0 2px;
}

.test_board {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.08);
  margin-bottom: 32px;
}

.test_board.confetti {
  background: url('../images/confeti.png') no-repeat center top / 100%;
}

.finish_confetti {
  position: absolute;
  left: 0;
  top: 20px;
}

.checklist_confetti {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
}

.test_chart {
  margin: 48px auto;
  position: relative;
  width: 176px;
  height: 176px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.test_chart:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  border-radius: 50%;
  background: linear-gradient(180deg, #272a2f 0%, #181b20 100%);
}

.test_chart:before {
  content: '';
  z-index: 1;
  position: absolute;
  width: 158px;
  height: 158px;
  background: #181b20;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.test_chart-percent {
  position: relative;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 0.01em;
  font-family: 'Roboto-Black', sans-serif;
  z-index: 1;
  color: #ffffff;
}

.test_chart-progress {
  opacity: 0.75;
  width: 228px;
  height: 228px;
  position: absolute;
  z-index: 1;
  transform: rotate(180deg);
}

.test_chart-progress span {
  display: none;
}

.achievement {
  max-width: 100%;
  width: 323px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  padding: 24px;
}

.achievement-img {
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
}

.achievement-img:after {
  content: '';
  z-index: -1;
  border-radius: 50%;
  position: absolute;
  left: 1px;
  top: 1px;
  width: 78px;
  height: 78px;
  background-color: #ffe39d;
}

.achievement-img.stub:after {
  border: 4px solid #ffd26d;
}

.section_list-icon.test .achievement-img {
  width: 48px;
  height: 48px;
}

.section_list-icon.test .achievement-img:after {
  width: 46px;
  height: 46px;
}

/*.achievement-img.stub {*/
/*  border: 4px solid #ffd26d;*/
/*}*/
.achievement-img.stub img {
  width: 75%;
  height: 75%;
  border-radius: 50%;
  object-fit: contain;
}

.achievement-name {
  font-size: 16px;
  line-height: 24px;
  min-height: 48px;
  font-family: 'Roboto-Medium', sans-serif;
  text-align: center;
  letter-spacing: 0.01em;
  margin-top: 24px;
}

.achievement-name.h-auto {
  min-height: auto;
}

.achievement-add {
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.01em;
  font-family: 'Roboto-Medium', sans-serif;
  padding-bottom: 24px;
}

.achievement-add span {
  color: #e1284c;
}

.achievement_hide-desc .achive_description {
  display: none;
}

.ahive_slider .slick-track {
  align-items: stretch;
}

.ahive_slider {
  overflow: hidden;
}

.ahive_slider .slick-slide {
  position: relative;
  align-self: stretch;
}

.achive_home {
  height: 100%;
  align-self: flex-start;
  position: relative;
  margin-right: 40px;
  width: 216px;
  flex: 0 216px;
}

.ahive_slider .slick-slide:last-child .achive_home {
  margin-right: 0;
}

.achive_description {
  /*display: none;*/
  z-index: 1;
  /*position: absolute;*/
  top: 100%;
}

.achive_home:hover {
  background-color: rgba(255, 255, 255, 0.04);
}

.text_danger {
  color: #fc2a52;
}

.ff-medium {
  font-family: 'Roboto-Medium', sans-serif;
}

.achive_home-wrap {
  /*margin: 0 -20px;*/
  /*overflow-y: visible;*/
  /*overflow-x: auto;*/
  /*display: flex;*/
  /*flex-wrap: nowrap;*/
  height: auto;
  padding-bottom: 16px;
}

.achive_home-wrap .achive_home:first-child {
  margin-left: 0;
}

.achive_home-wrap .achive_home:last-child {
  margin-right: 0;
}

.test_result-achieve {
  position: absolute;
  right: -140px;
  top: 60px;
  background-color: rgba(24, 27, 32, 0.6);
  backdrop-filter: blur(5px);
  width: 200px;
  height: auto;
}

.section_list-padding.disabled.test .achievement-img.stub:after {
  background: transparent;
  border: none;
}

.section_list-padding.disabled.test .achievement-img img {
  display: none;
}

.section_list-padding.test .section_list-test_stub {
  display: none;
}

.section_list-padding.disabled.test .section_list-test_stub {
  display: block;
}

.section_end {
  display: flex;
  align-items: center;
  min-height: 50px;
}

.section_left {
  position: static !important;
}

.news_list-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #181b20;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.news_list-item:hover,
.news_list-item:hover .news_list-courses_hidden {
  background: #1d2124;
}

.news_list-item:hover .news_list-about,
.news_list-item:hover .news_list-courses_hidden {
  border-color: transparent;
}

.news_list-img {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.08);
}

.news_list-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news_list-about {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 24px 0;
  flex: 1;
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.news_list-name {
  min-height: 54px;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 8px;
  font-family: 'Roboto-Bold', sans-serif;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.news_list-desc {
  min-height: 54px;
}

.news_list-bottom,
.news_article-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  /*min-height: 40px;*/
  margin-top: 12px;
}

.news_article-header {
  margin-top: 0;
  margin-bottom: 24px;
}

.news_article-title {
  text-align: center;
  padding: 0 32px 60px;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.01em;
  font-family: 'Roboto-Black', sans-serif;
}

.news_list-date {
  font-size: 12px;
  align-self: flex-start;
  margin-top: 8px;
  padding-bottom: 16px;
  text-align: center;
  letter-spacing: 0.01em;
}

.news_article-date {
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 0.01em;
  padding-bottom: 8px;
}

.news_article-header .news_list-date {
  line-height: 40px !important;
  align-self: flex-start;
  font-size: 16px;
}

.news_list-courses {
  /*padding-bottom: 16px;*/
  /*padding-top: 12px;*/
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
}

.news_list-courses.need_more .news_filters-item {
  margin-bottom: 8px;
}

.news_list-courses_hidden {
  padding: 0 16px;
  display: flex;
  flex-wrap: wrap;
  left: 0;
  top: calc(100% - 24px);
  position: absolute;
  z-index: 1;
  background: #181b20;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-top: 0;
}

.news_list-courses_hidden.show {
  padding-top: 0px;
}

.news_list-courses_more {
  background: #7459ff;
  min-width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-right: -16px;
  border-radius: 50%;
  border: 2px solid #1a1c21;
  font-size: 14px;
  font-family: 'Roboto-Medium', sans-serif;
  align-self: center;
}

.news_list-courses_wrap {
  padding: 2px;
  background: #1a1c21;
  z-index: 1;
  margin-right: -16px;
  border-radius: 50%;
}

.news_list-new {
  position: absolute;
  left: 0;
  font-size: 14px;
  top: 24px;
  height: 24px;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  background-color: #fc2a52;
  line-height: 27px;
  font-family: 'Roboto-Medium', sans-serif;
  letter-spacing: 0.01em;
}

.news_list-new:before {
  content: '';
  position: absolute; /* Абсолютное позиционирование */
  right: -12px;
  width: 12px;
  height: 12px;
  top: 0; /* Положение треугольника */
  border: 6px solid rgba(0, 0, 0, 0);
  /*border-top: 6px solid #FC2A52;*/
  /*border-left: 6px solid #FC2A52;*/
  border-bottom: 6px solid #fc2a52;
  border-left: 6px solid #fc2a52;
  transform: scale(1, -1);
}

.news_list-new:after {
  content: '';
  width: 12px;
  height: 12px;
  position: absolute; /* Абсолютное позиционирование */
  right: -12px;
  bottom: 0; /* Положение треугольника */
  border: 6px solid rgba(0, 0, 0, 0);
  border-bottom: 6px solid #fc2a52;
  border-left: 6px solid #fc2a52;
}

.news_list-item.new .news_list-new {
  display: flex;
}

.news_page-head {
  margin-bottom: 68px !important;
}

.news_filters {
  margin: 30px -8px 0;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.news_filters-item {
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  max-width: 135px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  font-family: 'Roboto-Medium', sans-serif;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 60px;
  margin: 0 8px;
  padding: 0 16px;
  /*transition: all .1s ease;*/
}
.news_filters-item.hidden {
  margin-bottom: 16px;
}
.news_filters-soon_icon {
  position: relative;
  top: -1px;
  padding-right: 4px;
  display: none;
}

.news_filters-item.active,
.news_filters-item:hover {
  background: rgba(255, 255, 255, 0.16);
  border-color: rgba(255, 255, 255, 0);
}

.news_filters-item.soon .news_filters-soon_icon {
  display: block;
}

.news_filters-item.soon {
  pointer-events: none;
  padding-right: 24px;
  cursor: default !important;
  border: 1px solid rgba(255, 255, 255, 0.16);
  color: rgba(255, 255, 255, 0.32);
}

.news_list-more {
  margin: 0 -16px;
  height: 24px;
  display: flex;
  flex: 1;
  min-width: 100%;
  align-items: center;
  justify-content: center;
}

.news_list-more.visible {
  transform: rotate(180deg);
}

.news_list-more:hover {
  background: rgba(255, 255, 255, 0.16);
}

.event_list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 40px 0;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: rgba(24, 27, 32, 0.9);
}

.event_gradient {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.event_list-item.new {
  background: transparent;
}

.event_list-item.new .event_gradient {
  display: block;
  z-index: 2;
  background: linear-gradient(112.95deg, #7459ff -19.98%, #fc2a52 236.98%), #7459ff;
  /*border: 0;*/
}

.event_list-item.new.show .event_gradient {
  z-index: 3;
}

.event_list-day {
  font-size: 80px;
  line-height: 94px;
  letter-spacing: 0.01em;
}

.event_list-month {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.01em;
}

.event_list-time {
  font-size: 24px;
  line-height: 19px;
  /* identical to box height, or 79% */
  padding-top: 24px;

  color: rgba(255, 255, 255, 0.64);
}

.event_list-month::first-letter {
  text-transform: uppercase;
}

.event_list-desc_wrap {
  position: relative;
  font-size: 18px;
  line-height: 27px;
  /* or 150% */
  height: 105px;
  /*overflow: hidden;*/
  margin-bottom: 24px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.64);
}

.event_list-desc {
  z-index: 1;
  /*background: rgba(24, 27, 32, 1);*/
  border-bottom: 0;
  margin: 0 -40px 24px;
  padding: 0 40px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.event_list-more {
  text-align: center;
  cursor: pointer;
  margin: 0 -40px;
  padding: 0 40px;
}

.event_list-more:hover {
  background: rgba(255, 255, 255, 0.02);
}

.event_list-item.show .event_list-desc {
  margin: 0 -41px 24px;
  z-index: 4;
  height: auto;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-top: 0;
  background: rgba(24, 27, 32, 1);
}

.event_list-item.show.new .event_list-desc {
  border: 0;
  margin: 0 -40px 24px;
  background: transparent;
}

.event_z-el {
  position: relative;
  z-index: 2;
}

.event_list-item.show .event_z-el {
  z-index: 3;
}

.event_list-item.show .event_list-more .svg-wrap {
  transform: rotate(180deg);
}

.calendar_date-event:after {
  content: '';
  width: 4px;
  height: 4px;
  background: linear-gradient(0deg, #fc2a52, #fc2a52), #7459ff;
  border-radius: 50%;
  position: absolute;
  right: 12px;
  top: 8px;
}

.ant-calendar-picker-input.ant-input {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 0;
  border: 0 !important;
}

.react-calendar {
  background: #2a2e31;
  backdrop-filter: blur(12px);
  border: 0;
  font-family: 'Roboto-Regular', sans-serif;
}

.react-calendar button {
  outline: none;
}

.react-calendar__tile {
  position: relative;
  color: rgba(255, 255, 255, 0.64);
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar button:enabled:hover,
.react-calendar__tile--active,
.react-calendar__navigation button[disabled],
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--hasActive {
  background: rgba(255, 255, 255, 0.08);
  outline: none;
}

.react-calendar__tile--now {
  background: transparent;
  color: #01cb65;
}

.react-calendar__month-view__days__day--weekend {
  color: rgba(255, 255, 255, 0.64);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: rgba(255, 255, 255, 0.1);
}

.abbr[data-original-title],
abbr[title] {
  cursor: default !important;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: none !important;
}

.react-calendar__month-view__weekdays__weekday,
.react-calendar__navigation__label {
  font-family: 'Roboto-Medium', sans-serif;
}

.calendar_wrapper {
  position: relative;
  width: 350px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: flex-end;
}

.events_calendar {
  position: absolute;
  right: 0;
  top: 100%;
  width: 350px;
}

.date-input {
  padding: 0 12px;
  cursor: default;
  color: rgba(255, 255, 255, 0.64);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px;
  height: 32px;
  background: rgba(255, 255, 255, 0.08);
}

.calendar_wrapper.with_date .date-input {
  color: #fff;
}

.date-input_icon-close,
.calendar_wrapper.with_date .date-input_icon:hover .date-input_icon-calendar {
  display: none;
}

.calendar_wrapper.with_date .date-input_icon:hover .date-input_icon-close {
  display: block;
}

.page_404 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 80px;
  text-align: center;
}

.page_404-404 {
  position: relative;
  z-index: 1;
  font-family: 'Roboto-Black', sans-serif;
  font-size: 200px;
  margin: 0;
}

.page_404-404-shadow {
  position: absolute;
  left: 20px;
  top: 20px;
  font-family: 'Roboto-Black', sans-serif;
  font-size: 200px;
  margin: 0;
  color: transparent;
  -webkit-text-stroke: 1px #fc2a52;
  text-stroke: 1px #fc2a52;
}

.page_404-title {
  padding-bottom: 40px;
  padding-top: 80px;
  font-size: 24px;
  line-height: 28px;
}

.auth_right {
  min-width: 45%;
  flex: 1 45%;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  width: 100%;
}

.auth_right-bg {
  height: 100%;
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 50%,
      rgba(24, 27, 32, 0) 63%,
      rgba(24, 27, 32, 0.226519) 66%,
      rgba(24, 27, 32, 0.375691) 69%,
      rgba(24, 27, 32, 0.60221) 75%,
      rgba(24, 27, 32, 0.78453) 83%,
      #181b20 95%
    ),
    rgba(24, 27, 32, 0.9);
  /*background: rgba(24, 27, 32, 0.4);*/
  backdrop-filter: blur(5px);
  min-width: 512px;
  padding-right: 24px;
  width: 100%;
  min-height: 100vh;
  padding-left: 88px;
  padding-top: 132px;
}

.auth_right-content {
  margin-top: 250px;
  padding-top: 40px;
  position: relative;
  width: 400px;
  z-index: 1;
  /*padding-top: 132px;*/
}

.auth_right-content_absolute {
  position: absolute;
  left: 0;
  bottom: 100%;
}

.login_input {
  height: 48px;
}

.social_other {
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  flex: 0 48px;
  min-width: 48px;
  background: rgba(255, 255, 255, 0.04);
}

.social_google {
  display: flex;
  padding-left: 16px;
  padding-right: 32px;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  background: #7459ff;
}

.login_separator {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #c4c4c4;
  margin: 32px 0;
}

.login_separator:after {
  content: '';
  position: absolute;
  left: 0;
  width: 170px;
  height: 1px;
  background: rgba(255, 255, 255, 0.08);
}

.login_separator:before {
  content: '';
  position: absolute;
  right: 0;
  width: 170px;
  height: 1px;
  background: rgba(255, 255, 255, 0.08);
}

.auth_left {
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 50%,
      rgba(24, 27, 32, 0) 63%,
      rgba(24, 27, 32, 0.226519) 66%,
      rgba(24, 27, 32, 0.375691) 69%,
      rgba(24, 27, 32, 0.60221) 75%,
      rgba(24, 27, 32, 0.78453) 83%,
      #181b20 95%
    ),
    rgba(24, 27, 32, 0.6);
  min-height: 100vh;
  min-width: 55%;
  flex: 1 55%;
  position: relative;
  display: flex;
  float: right;
  padding: 416px 50px 32px;
  justify-content: center;
  /*align-items: flex-end;*/
  flex-direction: column;
}

.auth_left-content {
  margin-left: 100px;
  align-self: flex-end;
  position: relative;
  max-width: 680px;
}

.auth_x {
  position: absolute;
  left: -33px;
  top: -16px;
}

.auth_error h1 {
  color: #fc2a52;
}

.auth_error {
  font-size: 12px;
  line-height: 18px;
}

.auth_error ul {
  list-style-type: inherit;
  padding-left: 16px;
}

.auth_error a {
  text-decoration: underline;
  color: #01cb65;
  font-size: 14px;
  font-family: 'Roboto-Black', sans-serif;
}

.auth_error a:hover {
  text-decoration: none;
  color: #01cb65;
}

.auth_error-app {
  color: #ffdd00;
  font-size: 14px;
  font-family: 'Roboto-Black', sans-serif;
}

.custom_input.error {
  border-bottom: 1px solid red;
}

.header_notific-btn {
  z-index: 333;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 40px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  backdrop-filter: blur(4px);
  transition: all 0.3s ease;
  position: relative;
}

.header_notific-btn.visible {
  z-index: 999;
}

.header_notific-btn:hover,
.header_notific-btn.visible {
  background: rgba(255, 255, 255, 0.16);
}

.header_notific-count {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  min-width: 12px;
  padding: 0 2px;
  z-index: 1;
  height: 12px;
  right: 4px;
  top: 4px;
  font-size: 9px;
  line-height: 12px;
  /* identical to box height */
  font-family: 'Roboto-Black', sans-serif;
  color: #ffffff;
  background: #fc2a52;
}

.header_events-count {
  right: 18px;
}

.header_notific-wrap {
  z-index: 3;
  cursor: default;
  position: absolute;
  width: 382px;
  padding: 30px;
  top: 42px;
  right: -54px;
  background-color: #fff;
  box-shadow: 0px 30px 20px rgba(45, 60, 88, 0.15);
  border-radius: 6px;
  display: none;
}

.header_notific-list {
  padding: 0 16px;
  cursor: default;
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  width: 100%;
  max-height: 480px;
  overflow-y: auto;
}

.header_notific-btn.visible .header_notific-wrap {
  display: block;
}

.header_notific-btn.visible:after {
  content: '';
  position: absolute; /* Абсолютное позиционирование */
  bottom: -10px; /* Положение треугольника */
  border: 3px solid transparent; /* Прозрачные границы */
  border-top: 3px solid #ffffff; /* Добавляем треугольник */
  border: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
}

.notif_item {
  position: relative;
  margin-bottom: 16px;

  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.04);
}

.notif_item:hover {
  background: rgba(255, 255, 255, 0.04);
}

.notif_item:last-child {
  margin-bottom: 0;
}

.notif_item-remove {
  background: transparent;
  border: 0;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
}

.notif_item-remove:focus {
  outline: none;
}

.free_modal .ant-modal-body {
  padding: 64px 100px;
}

.free_modal-img {
  width: 164px;
}

.free_modal-title {
  padding-top: 64px;
  padding-bottom: 24px;
  font-size: 40px;
  line-height: 50px;
  font-family: 'Roboto-Medium', sans-serif;
  letter-spacing: 0.01em;
  color: #01cb65;
}

.free_modal-text1 {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.free_modal-btn {
  display: flex;
  justify-content: center;
  padding-top: 64px;
}

.free_modal-text2 {
  padding-top: 24px;
  font-size: 18px;
  line-height: 27px;
  /* or 150% */
  color: rgba(255, 255, 255, 0.5);
}

.truncate_text-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.truncate_text-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

.truncate_text-4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
}

.free_modal-nota_icon {
  margin: 0 auto;
  display: flex;
  width: 164px;
  height: 164px;
  border-radius: 50%;
  border: 10px solid #fc2a52;
  justify-content: center;
  align-items: center;
}

.section_right .task_head_status {
  max-width: 100%;
}

.btn_loader {
  position: absolute;
  right: 24px;
}

.notif_text {
  padding-top: 8px;
  width: 100%;
  word-break: break-word;
}

.courses_layout .ant-layout-content {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.poll_input {
  background: rgba(255, 255, 255, 0.04);
  outline: none;
  padding: 12px 24px;
  color: #fff;
  font-size: 16px;
  height: 48px;
  width: 100%;
}

.poll_input::placeholder {
  color: rgba(255, 255, 255, 0.32) !important;
  font-size: 16px;
}

.auth_slider {
  pointer-events: none;
  overflow: hidden;
}

.course_continue-icon {
  text-align: center;
  min-width: 40px;
}

.slider_wrap {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;
}

.arrow {
  cursor: pointer;
  width: 48px !important;
  height: 336px;
  transform: scale(0.9);
  position: absolute;
  z-index: 1;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.16);
  /*border-radius: 50%;*/
  transition: background-color 0.3s ease;
}

.arrow:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.arrow_left {
  transform: rotate(180deg) scale(0.9);
  left: 0px;
}

.arrow_right {
  right: 0px;
}

.slider_wrap:hover .arrow {
  display: flex;
}

.course_head-product {
  cursor: pointer;
  margin-top: 16px;
  position: relative;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 16px;
  border-top-right-radius: 48px;
  background: #ff9533;
  font-size: 14px;
  line-height: 16px;
  font-family: 'Roboto-Medium', sans-serif;
}

.course_head-product:hover {
  background-color: rgba(255, 149, 51, 0.8);
}
.course_head-product:hover:after {
  background: linear-gradient(
    to right,
    rgba(255, 149, 51, 0) 0%,
    rgba(255, 149, 51, 0.1) 20%,
    rgba(255, 149, 51, 0.8) 100%
  );
}
.course_head-product:active {
  background-color: rgba(255, 149, 51, 0.9);
}
.course_head-product:active:after {
  background: linear-gradient(
    to right,
    rgba(255, 149, 51, 0) 0%,
    rgba(255, 149, 51, 0.1) 20%,
    rgba(255, 149, 51, 0.9) 100%
  );
}

.course_head-product:after {
  content: '';
  position: absolute;
  height: 24px;
  background: linear-gradient(
    to right,
    rgba(255, 149, 51, 0) 0%,
    rgba(255, 149, 51, 0.1) 20%,
    #ff9533 100%
  );
  width: 100px;
  top: 0;
  right: 100%;
}
.ReactModalPortal {
  /*position: relative;*/
  /*z-index: 1000;*/
}
.task_modal {
  padding: 32px;
  background-color: #181b20;
}

@media (max-width: 992px) {
  .section_list {
    padding-right: 24px;
  }
}

@media (max-width: 600px) {
  .accent_btn,
  .accent_btn-outline {
    min-width: 118px;
  }

  .authorized-body .test-container {
    display: block;
    width: 100%;
    margin: 0;
  }

  .authorized-body .test-column {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
  }
  .authorized-body .section_list {
    padding: 0;
    margin: 0 0 30px;
    overflow: visible;
  }
}
